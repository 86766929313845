import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CircularProgress, Avatar } from '@mui/material';
import WindoButton from 'components/common/Button';
import { isEmpty } from 'ui/lodash';
import Card from 'components/card/Product';
import styles from './Carousel.module.css';

function Carousel({
  items,
  cardStyle,
  onSelect,
  header,
  imgStyle,
  titleStyle,
  headerStyle,
  containerStyle,
  loading,
  type,
  showViewMore,
  viewMoreClick,
  dataId,
}) {

  const getImage = (item) => {

    if (type === 'catalog') {

      return item.img;

    }
    const variants = item?.variants?.length > 0 ? item.variants : [];
    const productImages = item?.images?.length > 0 ? item.images : [];
    const icon = item?.icon?.length > 0 ? item?.icon : [];
    if (icon.length > 0) {

      return icon[0];

    }
    if (variants.length > 0) {

      const variant = variants[0];
      const url = variant && variant.images[0].url;
      return url;

    }
    if (productImages.length > 0) {

      const variant = productImages[0];
      const url = variant && variant.url;
      return url;

    }
    return '';

  };

  const getTitle = (item) => {

    const value = item?.title || item?.name;
    return value || '';

  };

  function randColor(text) {

    const ascii = text?.charCodeAt(0) % 5;
    const colorArr = ['#B3E5FC', '#F8BBD0', '#E1BEE7', '#FFCDD2', '#F0F4C3'];
    return colorArr[ascii];

  }

  return (
    <div className={styles.section}>
      <div className={cx(headerStyle, 'flexBetween')}>
        {' '}
        {header}
        {' '}
        {showViewMore && (
          <WindoButton
            onClick={() => viewMoreClick(type)}
            title="View All"
            btnStyles={styles.btnStyles}
            endIcon={<img className={styles.icon} src="/images/store/viewMore.svg" alt="" />}
          />
        )}
      </div>
      <div className={containerStyle || styles.container}>
        {items.map((item, i) => (
          <>
            {type === 'product' && (
              <Card showBorder={true} dataId={dataId} cardStyle={cardStyle} tile={item} key={i} i={i} />
            )}
            {type !== 'product' && (
              <div
                key={i}
                className={cardStyle}
                data-id={dataId}
                data-value={getTitle(item)}
                role="presentation"
                onClick={() => {

                  // Add tracking page
                  if (onSelect) {

                    onSelect(item);

                  }

                }}
              >
                <Avatar
                  className={imgStyle}
                  style={isEmpty(imgStyle) ? {
                    background: randColor(item.title),
                    width: '98px',
                    height: '98px',
                    color: 'var(--black)',
                    fontSize: '16px',
                    fontWeight: 'var(--semi-bold)',
                    borderRadius: '18px'
                  } : {}}
                  src={getImage(item)}
                  alt=""
                >
                  {item?.title ? item.title.substring(0, 2) : 'NA'}
                </Avatar>
                {type === 'catalog' && (
                  <div
                    className={styles.border}
                    style={{
                      position: 'absolute',
                      width: '80px',
                      borderRadius: '50%',
                      top: '0',
                      left: '0px',
                    }}
                  >
                  </div>
                )}
                <div className={cx(titleStyle)}>{getTitle(item) || 'Shop'}</div>
              </div>
            )}
            {showViewMore && i === items.length - 1 && (
              <div role="none" onClick={() => viewMoreClick(type)} className={cx(styles.viewAll, 'fs12 vertical')}>
                <img src="/images/store/viewAll.svg" alt="" />
                View All
              </div>
            )}
          </>
        ))}
        {loading && <CircularProgress color="secondary" />}
        {!loading && items.length === 0 && (
          <div className="fs12 white"> No items found </div>
        )}
      </div>
    </div>
  );

}

Carousel.propTypes = {
  header: PropTypes.string.isRequired,
  cardStyle: PropTypes.any.isRequired,
  titleStyle: PropTypes.any.isRequired,
  headerStyle: PropTypes.any.isRequired,
  containerStyle: PropTypes.any.isRequired,
  imgStyle: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  viewMoreClick: PropTypes.any.isRequired,
  showViewMore: PropTypes.bool.isRequired,
  dataId: PropTypes.string.isRequired,
};

export default Carousel;
