import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import React from 'react';
import { ViewAllIcon, ViewAllIconTop } from 'components/shared/Icons/theme';
import { useLanguageLoader } from 'contexts/language';
import { Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { useIsCustomDomain } from 'contexts/profile';
import { Container } from 'components/layout/Container';
import cx from 'classnames';
import styles from './common.module.css';

export default function ViewAll({
  name,
  type,
  label,
  children,
  products,
  shop,
  containerClass,
}) {

  const lang = useLanguageLoader();
  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();

  if (!products?.length) {

    return null;

  }

  const onClick = () => {

    if (type === 'featured') {

      router.push({
        pathname: isCustomDomain ? '/featured' : `/${shop?.slug}/featured`,
      });
      return;

    }
    router.push(
      {
        pathname: isCustomDomain ? '/catalogs' : `/${shop?.slug}/catalogs`,
        query: {
          catalog: name,
        },
      }
    );

  };

  return (
    <div className={cx(styles.viewAll, containerClass)}>
      <Container>
        <div className={styles.head}>
          <div className={styles.name}>{name}</div>
          <Clickable onClick={onClick} className={styles.viewAllTop}>
            <span>{lang('common.viewAll')}</span>
            <ViewAllIconTop />
          </Clickable>
        </div>
        <Grid container spacing={2}>
          {children}
        </Grid>
        <div className={styles.footer}>
          <Clickable onClick={onClick}>
            <span>{label}</span>
            <ViewAllIcon />
          </Clickable>
        </div>
      </Container>
    </div>
  );

}

ViewAll.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  products: PropTypes.array.isRequired,
  shop: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
};

ViewAll.defaultProps = {
  containerClass: '',
};
