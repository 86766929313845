import Loader from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import styles from './Skeleton.module.css';
import { useIsDesktop } from 'contexts/profile';

export function Skeleton({ height }) {
  const top = height *  0.5 - 20 * 2;
  const isDesktop = useIsDesktop();
  const arr = isDesktop ? [1,2,3,4,5,6,7,8] : [1,2,3,4];
  return (
    <div className={styles.container}>
      <Grid container spacing={2}>
        {arr.map(i => (
          <Grid item xs={6} md={3} key={i}>
            <Loader animation={'wave'} variant={'rect'} width={'100%'} height={top}/>
            <div className={styles.padding}/>
            <Loader animation={'wave'} variant={'text'} />
            <Loader animation={'wave'} variant={'text'} width={'80%'} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
} 

Skeleton.propTypes = {
  height: PropTypes.number.isRequired,
};

export default Skeleton;