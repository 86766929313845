/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import styles from './Image.module.css';

function Image({
  src,
  borderStyles,
  onClick
}) {

  return (
    <div className="fullWidth fullHeight">
      <img
        width="100%"
        height="100%"
        alt=""
        role="none"
        onClick={onClick && onClick}
        className={borderStyles || styles.border}
        src={src}
        loading={'lazy'}
      />
    </div>
  );

}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  borderStyles: PropTypes.any.isRequired,
  onClick: PropTypes.func
};

Image.defaultProps = {
  onClick: null
};

export default Image;
