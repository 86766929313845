import PropTypes from 'prop-types';

export const defaultProps = {
  savedFeed: [],
  templates: [],
  catalogList: [],
};

export const props = {
  shop: PropTypes.object.isRequired,
  savedFeed: PropTypes.array,
  templates: PropTypes.array,
  catalogList: PropTypes.array,
};
