/* eslint-disable max-len, react/no-multi-comp */
export function ViewAllIcon() {

  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.08438 10.6523C3.61813 10.186 3.61813 9.43168 4.08438 8.96543L5.83 7.24168H1.21125C0.541875 7.24168 0 6.6998 0 6.03105V5.95292C0 5.28417 0.541875 4.7423 1.21125 4.7423H5.81313L4.08438 3.03355C3.61813 2.5673 3.61813 1.81293 4.08438 1.34668C4.55563 0.882925 5.3175 0.880425 5.78875 1.34668L9.63625 5.15792C10.1025 5.62167 10.1025 6.37855 9.63625 6.84418L5.78875 10.6529C5.3175 11.1186 4.55563 11.1186 4.08438 10.6523Z"
        fill="var(--primary)"
      />
    </svg>
  );

}

export function ViewAllIconSalvia() {

  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.687 10.289C4.56929 10.1687 4.50765 10.0157 4.50209 9.82979C4.49695 9.64393 4.55324 9.49087 4.67095 9.37061L7.81701 6.1563L0.642055 6.1563C0.460139 6.1563 0.307544 6.09332 0.18427 5.96737C0.0614233 5.84186 0 5.68618 0 5.50031C0 5.31445 0.0614233 5.15855 0.18427 5.0326C0.307544 4.90709 0.460139 4.84433 0.642055 4.84433L7.81701 4.84433L4.67095 1.63002C4.55324 1.50976 4.49695 1.35669 4.50209 1.17083C4.50765 0.984971 4.56929 0.831908 4.687 0.711645C4.80471 0.591381 4.95452 0.53125 5.13644 0.53125C5.31835 0.53125 5.46816 0.591381 5.58587 0.711645L9.82343 5.04113C9.88764 5.09579 9.93323 5.16401 9.96019 5.24579C9.98673 5.32801 10 5.41285 10 5.50031C10 5.58778 9.98673 5.66978 9.96019 5.74631C9.93323 5.82284 9.88764 5.8939 9.82343 5.9595L5.58587 10.289C5.46816 10.4092 5.31835 10.4694 5.13644 10.4694C4.95452 10.4694 4.80471 10.4092 4.687 10.289Z" fill="var(--primary)"/>
    </svg>

  );

}

export function ViewAllIconTop() {

  return (
    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.8902 5.99866L0.257225 1.46106C0.175783 1.38168 0.111152 1.28731 0.0670503 1.18336C0.0229481 1.07942 0.000244141 0.967949 0.000244141 0.855372C0.000244141 0.742795 0.0229481 0.631329 0.0670503 0.527382C0.111152 0.423436 0.175783 0.32906 0.257225 0.249681C0.422213 0.0897193 0.644912 0 0.876976 0C1.10904 0 1.33174 0.0897193 1.49673 0.249681L6.74556 5.38891C6.90415 5.54496 6.99528 5.75491 7.00001 5.97513C7.00474 6.19534 6.92271 6.40887 6.77096 6.57133L1.50036 11.7507C1.41921 11.8305 1.32282 11.8938 1.21671 11.9371C1.11059 11.9803 0.996818 12.0027 0.881888 12.0028C0.766958 12.003 0.65312 11.981 0.546875 11.938C0.440629 11.8951 0.344055 11.832 0.262668 11.7525C0.181281 11.6729 0.116675 11.5785 0.0725375 11.4744C0.0284001 11.3704 0.00559628 11.2589 0.0054278 11.1463C0.00508753 10.9187 0.0969668 10.7004 0.260854 10.5393L4.8902 5.99866Z"
        fill="var(--primary)" />
    </svg>
  );

}

export function PlusIcon() {

  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.65" y1="5.35" x2="10.35" y2="5.35" stroke="var(--primary)" strokeWidth="1.3" strokeLinecap="round" />
      <line x1="5.65" y1="0.65" x2="5.65" y2="10.35" stroke="var(--primary)" strokeWidth="1.3" strokeLinecap="round" />
    </svg>
  );

}

export function MinusIcon() {

  return (
    <svg width="13" height="2" viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.75" y1="1.25" x2="12.25" y2="1.25" stroke="var(--primary)" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );

}

export function ColorCheck() {

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="7" fill="#32BA46" />
      <path d="M4.19922 6.89587L6.56172 8.79175L10.4992 5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

}
