import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useIsCustomDomain } from 'contexts/profile';
import { Timer } from  'components/store/Timer';
import styles from './Templates.module.css';

function Templates({ templates, shop }) {

  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();

  if (templates.length === 0) {

    return null;

  }

  return (
    <div className={cx(styles.single)}>
      {templates?.map((x, i) => (
        <div
          key={i}
          role="none"
          className={cx(styles.card)}
          style={{ backgroundImage: `url(${x?.image})` }}
          onClick={() => {

            const isCatalog = x.type === 'catalog';
            if (isCatalog) {

              router.push(
                {
                  pathname: isCustomDomain ? '/catalogs' : `/${shop?.slug}/catalogs`,
                  query: {
                    catalogs: x.items
                  },
                }
              );
              return;

            }
            router.push(
              {
                pathname: isCustomDomain ? `/template/${x._id}` : `/${shop?.slug}/template/${x._id}`,
              }
            );

          }}
        >
          <div className={styles.cardTitle}>
            {x.title}
          </div>
          <div className={styles.cardDesc}>
            {x.description}
          </div>
          <>
            <Timer shop={shop} timerId={x.timerId} />
          </>
        </div>
      ))}
    </div>
  );

}

Templates.defaultProps = {
  templates: []
};

Templates.propTypes = {
  templates: PropTypes.array,
  shop: PropTypes.object.isRequired
};

export default Templates;
