import PropTypes from 'prop-types';
import styles from './NoOrders.module.css';

export default function NoOrders({ title, icon }) {

  return (
    <div className="marginSTopBottom fullWidth textCenter fs12 grey">
      <img src={icon} alt="" />
      <div className={styles.text}>
        {' '}
        {title}
        .
        {' '}
      </div>
    </div>
  );

}

NoOrders.defaultProps = {
  title: 'You haven’t ordered any items yet!',
  icon: '/images/orders/noOrders.svg'
};

NoOrders.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
};
