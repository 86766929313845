import styles from './GetPrice.module.css'
import PropTypes from 'prop-types';
export default function GetPrice({ price }) {
  const amount = price?.toLocaleString();
  if (amount?.includes('.')) {
    const [number, decimal] = (+amount).toFixed(2).split('.')
    return (<span>{number + '.'}<span className={styles.decimal}>{decimal}</span></span>)
  }
  return <span>{amount}</span>
}

GetPrice.propTypes = {
  price: PropTypes.number.isRequired,
}
