// import { Clickable } from 'phoenix-components';
import styles from './ProductTags.module.css'
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useIsCustomDomain } from 'contexts/profile';

export default function ProductTags({ product, shop }) {

  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();

  const openCatalog = name => () => {

    router.push(
      {
        pathname: isCustomDomain ? '/tags' : `/${shop?.slug}/tags`,
        query: {
          tag: name
        },
      }
    );

  };

  return(
    <div className={styles.container}>
      {product?.tags?.map((tag, index) => (
        <div
          key={index}
          className={styles.button}
          style={{
            color: tag?.textColor,
            backgroundColor: tag?.background,
          }}
          onClick={openCatalog(tag?._id)}
        >
          {tag?.name}
        </div>
      ))}
    </div>
  )
}

ProductTags.propTypes = {
  product: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}