import * as React from 'react';
import { Dialog} from '@mui/material';
import styles from './DisablePopup.module.css';

export default function DisablePopup({blocked}) {

  return (
    <div>
      <Dialog
        open={blocked}
      >
        <div          
          className={styles.dialog}>
          <div className='flex flex-col w-full h-full'>
            <div className={styles.image}>
              <img src="/images/common/notAllowed.svg" alt="image" className={styles.img} />
            </div>
            <div className={styles.content}>
              <div className={styles.wrnText}>
              This shop has been blocked due to non-compliance to our policies
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
