import React from 'react';
import {
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Button.module.css';

function WindoButton({
  title, onClick, disabled, type, btnStyles, href, endIcon, dataId, dataValue
}) {

  return (
    <Button
      href={href}
      onClick={onClick}
      data-id={dataId}
      data-value={dataValue}
      className={cx(styles.padded, type === 'secondary' ? styles.secondary : btnStyles || styles.primary)}
      disabled={disabled}
      variant="contained"
      endIcon={endIcon}
    >
      {title}
    </Button>
  );

}

WindoButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  dataId: PropTypes.string,
  dataValue: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  href: PropTypes.string,
  btnStyles: PropTypes.string,
  endIcon: PropTypes.any,
};

WindoButton.defaultProps = {
  dataId: undefined,
  dataValue: undefined,
  disabled: false,
  href: undefined,
  endIcon: undefined,
  type: '',
  btnStyles: '',
};

export default WindoButton;
