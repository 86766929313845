import { get } from "lodash";

export function isNullImage(url) {

  return url === 'https://becca-cdn.windo.live/misc/no_image.png';

}

export function getProductImage(product) {

  const url = product.images[0]?.url;
  if (url === 'https://becca-cdn.windo.live/misc/no_image.png') {

    return '/images/noImage.svg';
  
  }
  return url;

}

export function mapProductImage(image) {

  if (image.url === 'https://becca-cdn.windo.live/misc/no_image.png') {

    return {
      ...image,
      url: '/images/noImage.svg',
    };
  
  }
  return image;

}

export function getProductImageNull(product) {

  const url = product.images[0]?.url;
  if (isNullImage(url)) {

    return null;
  
  }
  return url;

}

export function getDiscount(product) {

  const {
    amount,
    discountedAmount
  } = product;
  if (amount === discountedAmount) {

    return 0;
  
  }
  return (((amount - discountedAmount) * 100) / amount).toFixed();
}

export const rulesCheckFromProps = (count, item, quantitySchema) => {
  if (item) {
    if(quantitySchema.enabled) {
      const type = get(quantitySchema, 'type', 'infinite');
      if(type === 'infinite') {
        return true;
      }
      if(type === 'finite' && !quantitySchema.maxQuantity) {
        return true;
      }
      return count + getQuantity(count, quantitySchema) <= quantitySchema.maxQuantity;
    } else {
      return true;
    }
  }
  return true;
};

const ifCountExistsDo = (count, quantitySchema, isDelete) => {
  if (!count || (isDelete && count === quantitySchema.minQuantity)) {
    return quantitySchema.minQuantity;
  }
  return 1;
}

export const getQuantity = (count, quantitySchema, isDelete = false) => {
  if(quantitySchema.enabled) {
    const type = get(quantitySchema, 'type', 'infinite');
    if(type === 'infinite') {
      return ifCountExistsDo(count, quantitySchema, isDelete);
    }
    if (type === 'finite' && quantitySchema.maxQuantity) {
      return ifCountExistsDo(count, quantitySchema, isDelete);
    }
    return ifCountExistsDo(count, quantitySchema, isDelete);
  } else {
    return  1;
  }
}

