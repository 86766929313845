import PropTypes from 'prop-types';
import ViewAll from 'components/store/common/ViewAll';
import React from 'react';
import Skeleton from './Skeleton';
import { useLanguageLoader } from 'contexts/language';
import { useCatalogProducts } from 'hooks/product';
import _ from 'ui/lodash';
// import Loader from '@mui/lab/Skeleton';

function ViewAllCatalog({
  shop,
  name,
  component: Component,
  size,
  props,
  height,
  containerClass,
  getPropsFunc,
}) {
  const lang = useLanguageLoader();
  const [products, isLoading] = useCatalogProducts(shop._id, name);

  if (isLoading) {
    return (
      <Skeleton height={height} />
    );
  }

  if (products.length === 0) {
    return null;
  }

  const getProps = i => {
    if(getPropsFunc){
      return getPropsFunc(i, products);
    }
    return _.get(props, i, {});
  };

  return (
    <ViewAll
      shop={shop}
      type="catalog"
      products={products}
      name={name}
      containerClass={containerClass}
      label={`${lang('common.browseAll')} ${name} ${lang('common.products')}`}
    >
      {products.slice(0, size)
        .map((product, idx) => (
          <Component key={product._id} shop={shop} product={product} {...getProps(idx)} />
        ))}
    </ViewAll>
  );
}

ViewAllCatalog.propTypes = {
  shop: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  props: PropTypes.array,
  height: PropTypes.number,
  containerClass: PropTypes.string,
  getPropsFunc: PropTypes.func,
};

ViewAllCatalog.defaultProps = {
  props: [],
  containerClass: '',
  getPropsFunc: null,
};

export default ViewAllCatalog;
