import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { getUserIcon } from 'components/utils/icon';
import ShowMoreText from 'react-show-more-text';
import Carousel from 'components/shared/Carousel/Carousel';
import { useInfiniteShopProducts } from 'hooks/product';
import NoOrders from 'components/home/NoOrders';
import Templates from 'components/shop/Templates';
import { useIsCustomDomain, useIsDesktop } from 'contexts/profile';
import { useLanguageLoader } from 'contexts/language';
import { defaultProps, props } from 'containers/themes/props/store';
import { TopBar } from 'components/store/TopBar';
import { Container } from 'components/layout/Container';
import { BorderBottom } from 'components/layout/BorderBottom';
import { ProductCard } from 'components/store/celosia/ProductCard';
import ViewAll from 'components/store/common/ViewAll';
import styles from './store.module.css';
import ViewAllCatalogLazy from 'components/store/common/ViewAllCatalogLazy';
import ShopUtils from 'ui/lib/shopUtils';
import DisablePopup from 'components/store/common/DisablePopup';
const emptyProducts = '/images/store/noProducts.svg';

function CelosiaStore({
  shop,
  savedFeed,
  templates,
  catalogList: catalogs
}) {
  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();
  const isDesktop = useIsDesktop();
  const [expanded, setExpanded] = useState(false);
  const showTemplate = ShopUtils.showBanner(shop);
  const shopProfileEnabled = ShopUtils.isSectionEnabled(shop, 'shopProfile');
  // const marketingBannersEnabled = ShopUtils.isSectionEnabled(shop, 'marketingBanners');
  const collectionsEnabled = ShopUtils.isSectionEnabled(shop, 'collections');
  const featuredProductsEnabled = ShopUtils.isSectionEnabled(shop, 'featuredProducts');
  const productsEnabled = ShopUtils.isSectionEnabled(shop, 'products');
  const lang = useLanguageLoader();
  const feedTitle = ShopUtils.getTitle(shop, 'featuredProducts');
  const savedTitle = lang('store.headings.featuredProducts') === feedTitle ?
    lang('store.headings.featuredProducts') : feedTitle;
  const collectionsTitle = ShopUtils.getTitle(shop, 'collections');
  const savedCollectionsTitle = lang('store.headings.collections') === collectionsTitle ?
    lang('store.headings.collections') : collectionsTitle;
  const [products] = useInfiniteShopProducts(savedFeed);
  const layoutConfig = shop?.uiConfig?.layoutConfig;
  const templateIdShopProfile = layoutConfig?.shopProfile?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundShopProfileTemplates = templates?.filter(template => templateIdShopProfile.includes(template._id));
  const templateIdCollections = layoutConfig?.collections?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundCollectionsTemplates = templates?.filter(template => templateIdCollections.includes(template._id));

  const templateIdFeaturedProducts = layoutConfig?.featuredProducts?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundFeaturedProductsTemplates =
  templates?.filter(template => templateIdFeaturedProducts.includes(template._id));

  const templateIdProducts = layoutConfig?.products?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundProductsTemplates = templates?.filter(template => templateIdProducts.includes(template._id));
  
  const executeOnClick = (value) => {
    setExpanded(value);
  };

  const handleCatalogClick = (catalog) => {
    const val = catalog.title || catalog.label || catalog.value;
    router.push(
      {
        pathname: isCustomDomain ? '/catalogs' : `/${shop?.slug}/catalogs`,
        query: {
          catalog: val
        },
      }
    );
  };

  const getPropsForCard = (_idx, products)=> {
    return {variant: (products?.length <= 3 && isDesktop) ? 'large' : 'default'};
  }

  const items = catalogs.map(x => ({
    title: x.label,
    img: x.img
  }));

  return (
    <div className={styles.profileSection}>
      <TopBar shop={shop} catalogs={catalogs} showTabs={false}/>
      <div id="shopScroll1"/>
      <DisablePopup blocked={shop?.blocked} />
      {shopProfileEnabled &&
        <Container>
          <div className={cx(styles.relative)}>
            <img alt={shop?.name} src={getUserIcon(shop)} className={styles.dp}/>
            <div className={cx(styles.flex, 'fullWidth')}>
              <div className={cx(styles.fs16)}>
                {shop?.name}
              </div>
              <ShowMoreText
                lines={2}
                more="More"
                less="Less"
                className={cx('fs12', styles.rte)}
                anchorClass={styles.anchor}
                onClick={executeOnClick}
                expanded={expanded}
                width={typeof window !== 'undefined' ? document.body.clientWidth - 32 : 0}
              >
                <div
                  id="RTE"
                  dangerouslySetInnerHTML={{ __html: shop?.description }}
                >
                </div>
              </ShowMoreText>
            </div>
          </div>
        </Container>
      }
      <>
        {showTemplate && <Templates templates={foundShopProfileTemplates} shop={shop}/>}
      </>
      {collectionsEnabled &&
      <>
        <div id="shopScroll2"/>
        <Container>
          {(catalogs?.length > 1) &&
          <div className={styles.catalogHeadContainer}>
            <div className={styles.head}>
              <div className={styles.name}>{savedCollectionsTitle}</div>
            </div>
          </div>}
          {catalogs?.length > 1 &&
          <Carousel
            items={items}
            loading={false}
            header=""
            dataId="shop_profile_catalog"
            type="catalog"
            headerStyle={styles.header}
            cardStyle={styles.cateCardStyles}
            titleStyle={styles.cateTitleStyle}
            containerStyle={cx(styles.containerStyle, {
              [styles.center]: items.length === 1
            })}
            onSelect={handleCatalogClick}
          />
          }
          {(isDesktop && catalogs?.length === 1) &&
          <div className={styles.catalogContainer}>
            <div
              className={styles.singleCatalog}
              style={{
                backgroundImage: `url(${catalogs[0]?.img || 'https://becca-cdn.windo.live/misc/no_image.png'})`
              }}
              onClick={() => handleCatalogClick(catalogs[0])}
            >
            </div>
            <div className={cx(styles.singleCatalogTitle)}>{catalogs[0].label}</div>
          </div>
          }
        </Container>
      </>
      }
      {showTemplate && <Templates templates={foundCollectionsTemplates} shop={shop}/>}
      <div id="shopScroll3"/>
      <Container>
        {products.length === 0 && (
          <div className={styles.overflow1}>
            <div className={styles.head1}>Products</div>
            <NoOrders
              title="Shop does not have any products yet!"
              icon={emptyProducts}
            />
          </div>
        )}
        {featuredProductsEnabled &&  (
          <LazyLoad once={true} height={476} offset={100}>
            <ViewAll
              shop={shop}
              products={savedFeed}
              type="featured"
              name={savedTitle}
              label={`${lang('common.browse')} ${savedTitle}`}
            >
              {savedFeed.slice(0, 8)
                .map(product => (
                  <ProductCard
                    key={product._id} shop={shop} product={product}
                    variant={(savedFeed.length <= 3 && isDesktop) ? 'large' : 'default'}
                  />
                ))}
            </ViewAll>
          </LazyLoad>
        )}
        {showTemplate && <Templates templates={foundFeaturedProductsTemplates} shop={shop}/>}
        {productsEnabled && (
          <>
            {catalogs.map(catalog => (
              <ViewAllCatalogLazy
                height={476}
                key={catalog.value}
                shop={shop}
                name={catalog.value}
                component={ProductCard}
                getPropsFunc={getPropsForCard}
                size={8}
              />
            ))}
          </>
        )}
        {showTemplate && <Templates templates={foundProductsTemplates} shop={shop}/>}
      </Container>
      <BorderBottom/>
    </div>
  );
}

CelosiaStore.defaultProps = defaultProps;

CelosiaStore.propTypes = props;

export default CelosiaStore;
