export async function shareText(text, url) {
  if('share' in navigator) {
    try {
      await navigator.share({
        text: text,
        url,
      });
    }catch(e) {
      // ignore error
    }
    return;
  }
  //     const url = `mailto:team@mywindo.shop?body=${encodeURIComponent(shareToUser)}`;

  const uri = `mailto:?body=${encodeURIComponent(text)}`;
  window.open(uri, '_blank');
}
