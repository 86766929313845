import { useEffect, useMemo, useState } from 'react';
import { addLike, getProductDetails, listSavedProducts, listShopProducts } from 'ui/api/products';
import { Loader, SnackBar } from 'ui/services';

export function useInfiniteSavedProducts(initialProducts = []) {
  const [products, setProducts] = useState(initialProducts);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async page => {
    try {
      Loader.show();
      const data = await listSavedProducts(page);
      Loader.hide();
      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setProducts([...products, ...data]);
    } catch (e) {
      SnackBar.showError(e);
      Loader.hide();
    }
  };

  return [products, loadMore, hasMore];
}

export function useCatalogProducts(shopId, name) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const filters = useMemo(() => {
    if (!name) {
      return {};
    }
    if (Array.isArray(name)) {
      return name.length > 0 ? { catalogs: { $in: name } } : {};
    }
    return { catalogs: { $in: [name] } };
  }, [name]);

  const fetch = async () => {
    setLoading(true);
    try {
      const data = await listShopProducts(shopId, 0, {
        sorts: { createdAt: -1 },
        filters,
      });
      setProducts(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [shopId, name]);

  return [products, loading];
}

export function useInfiniteShopProducts(initialProducts = []) {
  const [products, setProducts] = useState(initialProducts);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async (id, page, filters) => {
    try {
      if (page === 0) {
        Loader.show();
      }
      const data = await listShopProducts(id, page, filters);
      Loader.hide();
      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setHasMore(true);
      if (page === 0) {
        // filters change
        setProducts(data);
        return;
      }
      setProducts([...products, ...data]);
    } catch (e) {
      SnackBar.showError(e);
      Loader.hide();
    }
  };

  return [products, loadMore, hasMore];
}

export function useInfiniteCatalog(initialProducts = []) {
  const [products, setProducts] = useState(initialProducts);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async (id, page, filters) => {
    if (page === 0) {
      Loader.show();
    }
    try {
      const data = await listShopProducts(id, page, filters);
      Loader.hide();
      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setHasMore(true);
      if (page === 0) {
        // filters change
        setProducts(data);
        return;
      }
      setProducts([...products, ...data]);
    } catch (e) {
      SnackBar.showError(e);
      Loader.hide();
    }
  };

  return [products, loadMore, hasMore];
}

export function useLikeProduct() {
  const addProductLike = async (id) => {
    try {
      await addLike(id);
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  return [addProductLike];
}

export function useLazyProduct(item) {
  const [product, setProduct] = useState(null);

  const loadProduct = async () => {
    if (product) {
      return product;
    }
    try {
      const product = await getProductDetails(item._id);
      setProduct(product);
      return product;
    } catch (e) {
      SnackBar.showError(e);
      return null;
    } finally {
      Loader.hide();
    }
  };

  return loadProduct;
}
