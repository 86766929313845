import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Image } from 'components/shared/Skeleton';
import cx from 'classnames';
import { getCurrency, getFlat } from 'ui/utils';
import { Clickable } from 'phoenix-components';
import { shareProduct } from 'components/utils/share';
import { useIsCustomDomain } from 'contexts/profile';
import { useLanguageLoader } from 'contexts/language';
import styles from './Product.module.css';
import { get } from 'ui/lodash';
import { shareText } from 'ui/lib/share';

const track = '/images/store/skuShare.svg';

function Card({
  tile, key, i, cardStyle, dataId, showBorder, shop
}) {

  const router = useRouter();

  const isCustomDomain = useIsCustomDomain();


  const lang = useLanguageLoader();
  const isOOS = tile?.status === 'out of stock';

  const showBanner = !get(shop, 'featureConfig.banner', true);

  const generateShareText = () => `${lang('store.words.hello')}!
  

${lang('store.alerts.iFoundThis')} ${tile?.shopName}.
${lang('store.words.productName')} - *${tile?.title}*
${lang('store.words.price')} - *${getCurrency(tile?.discountedAmount, tile?.currency)}*
${lang('store.alerts.checkOutHere')}
${shareProduct(tile?.shopSlug, tile.slug, isCustomDomain)}

${tile?.shopName}
${showBanner ? 'Created with ❤️ on WINDO' : ''}
`;


  const generateWhatsappUrl = () => {
    const text = generateShareText();
    shareText(text, shareProduct(tile?.shopSlug, tile.slug, isCustomDomain));
  };

  const getPrice = (item, original) => (original
    ? item?.amount !== item?.discountedAmount ? checkDecimal(item?.amount, item?.currency) : ''
    : checkDecimal(item?.discountedAmount, item?.currency));

  function checkDecimal(amount, currency) {
    const ip = getCurrency(amount, currency)
    if (ip.includes('.')) {
      const [number, decimal] = ip.split('.')
      return (<span>{number + '.'}<span className={styles.decimal}>{decimal}</span></span>)
    }
    return <span>{ip}</span>
  }

  return (
    <div
      key={key}
      role="presentation"
      data-id={dataId}
      data-value={tile._id}
      className={cx(styles.tile, cardStyle)}
      onClick={async (e) => {

        const x = document.getElementById(`button${i}`);
        if (x?.contains(e.target)) {

          e.preventDefault();
          e.stopPropagation();
          return;

        }
        await router.push({ pathname: isCustomDomain ? `/${tile.slug}` : `/${tile.shopSlug}/${tile.slug}` });

      }}
    >
      <div className={styles.height70}>
        <Image borderStyles={styles.border10} src={tile?.images[0]?.url} />
        {isOOS && (
          <span className={styles.oos}>{lang('buttons.soldOut1')}</span>
        )}
      </div>
      <div className={cx(showBorder && styles.border1)}>
        <div className={cx(styles.desc)}>
          {tile?.title?.replace(/<(.|\n)*?>/g, '')}
        </div>
      </div>
      <div className={cx(showBorder && styles.border2)}>
        <div className={styles.price}>
          <span>{getPrice(tile)}</span>
          <span className={cx('strikeLine paddingSLeft', styles.primary2)}>{getPrice(tile, true)}</span>
          {getPrice(tile, true) && (
            <span className={cx('paddingSLeft', styles.green)}>{getFlat(tile)}</span>
          )}
        </div>
      </div>
      <Clickable
        className={styles.share}
        onClick={generateWhatsappUrl}
      >
        <img id={`button${i}`} src={track} alt="" />
      </Clickable>
    </div>
  );

}

Card.propTypes = {
  tile: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  cardStyle: PropTypes.number.isRequired,
  dataId: PropTypes.string.isRequired,
  showBorder: PropTypes.bool,
  shop: PropTypes.object
};

Card.defaultProps = {
  showBorder: false,
  shop: {},
};

export default Card;
