import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import { getDiscount, getProductImage } from 'components/store/utils';
import { shareProduct } from 'components/utils/share';
import { get } from 'ui/lodash';
import { shareText } from 'ui/lib/share';
import { getCurrency } from 'ui/utils';
import { Grid } from '@mui/material';
import ProductColors from 'components/store/common/ProductColors';
import ProductTags from 'components/store/common/ProductTags';
import { useRouter } from 'next/router';
import { useIsCustomDomain } from 'contexts/profile';
import cx from 'classnames';
import { useLanguageLoader } from 'contexts/language';
import styles from './ProductCard.module.css';
import GetPrice from 'components/store/common/GetPrice';

const track = '/images/store/skuShare.svg';

export function ProductCard({
  shop,
  product,
  variant,
}) {

  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();
  const img = getProductImage(product);
  const lang = useLanguageLoader();
  const isOOS = product?.status === 'out of stock';

  const discount = getDiscount(product);
  const showBanner = !get(shop, 'featureConfig.banner', true);

  const generateShareText = () => `${lang('store.words.hello')}!
  ${lang('store.alerts.iFoundThis')} ${shop?.name}.
  ${lang('store.words.productName')} - *${product?.title}*
  ${lang('store.words.price')} - *${getCurrency(shop?.discountedAmount, shop?.currency)}*
  ${lang('store.alerts.checkOutHere')}
  ${shareProduct(shop?.slug || 'shop', product.slug, isCustomDomain)}

  ${shop?.name}
  ${showBanner ? 'Created with ❤️ on WINDO' : ''}
`;

  const generateWhatsappUrl = (e) => {
    e.stopPropagation();
    const text = generateShareText();
    shareText(text, shareProduct(shop?.slug || 'shop', product.slug, isCustomDomain));
  };

  const onClick = (_, value) => {
    let url = isCustomDomain ? `/${product.slug}` : `/${product.shopSlug}/${product.slug}`;
    if(!window.isWebView) {
      if (value) {
        url = url + `?color=${value}`;
      }
      window.open(url,'_blank');
    }
    else
      router.push(url);
  };

  const isLarge = variant === 'large';

  return (
    <Grid item={true} xs={6} md={isLarge ? 4 : 3}>
      <div
        className={cx(styles.container, {
          [styles.large]: isLarge,
        })}
      >
        <Clickable className={styles.click} onClick={onClick}>
          <div className={styles.imgContainer}>
            <img loading={'lazy'} className={styles.img} src={img} alt=""/>
            {isOOS && (
              <span className={styles.oos}>{lang('buttons.soldOut1')}</span>
            )}
            <div
              className={styles.share}
              onClick={generateWhatsappUrl}
            >
              <img src={track} alt=""/>
            </div>
          </div>
        </Clickable>
        <ProductColors onClick={onClick} product={product} />
        <div className={styles.name}>
          {product.title}
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.priceContainer}>
            <div className={styles.price}>
              <div className={styles.amount}>
                <span>
                  {shop.currency}
                </span>
                {' '}
                <GetPrice price={product.discountedAmount}/>
              </div>

              <div className={styles.unit}>
                {/* Per*/}
                {/* {' '}*/}
                {product.unit.value === 1 ? '' : product.unit.value}
                {' '}
                {product.unit.type}
              </div>
            </div>
            {discount > 0 && (
              <div className={styles.price2}>
                <div className={styles.originalAmount}>
                  {shop.currency}
                  {' '}
                  {product.amount}
                </div>
                <div className={styles.discount}>
                  {discount}
                  % OFF
                </div>
              </div>
            )}
            <ProductTags product={product} shop={shop} />
          </div>
        </div>
      </div>
    </Grid>
  );

}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

ProductCard.defaultProps = {
  variant: 'default',
};

export default ProductCard;
