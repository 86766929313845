function genericShare(type, id, direct = false) {

  const token = encodeURIComponent(Buffer.from(JSON.stringify({ type, id, shared: true }))).toString('base64');
  if (direct) {

    if (type === 'product') {

      return `https://do.windo.shopping/p/${id}`;
    
    }
    if (type === 'shop') {

      return `https://do.windo.shopping/${id}`;
    
    }
    return `https://app.windo.live/redirect?token=${token}`;
  
  }
  return `https://windo.onelink.me/HbMF/5d24c4ad?token=${token}`;

}

function getUrl() {

  return `${window.location.protocol}//${window.location.host}`;

}

export function sharePost(id, direct = false) {

  return genericShare('post', id, direct);

}

export function shareProduct(shop, id, customDomain) {

  if (customDomain) {

    return `${getUrl()}/${id}`;
  
  }
  return `https://mywindo.shop/${shop}/${id}`;

}

export function shareProfile(id, direct = false) {

  return genericShare('profile', id, direct);

}

export function shareShop(id, customDomain) {

  if (customDomain) {

    return `${window.location.protocol}//${window.location.host}/`;
  
  }
  return `https://mywindo.shop/${id}`;

}

export function shareSellerApp() {

  return 'https://windo-seller.onelink.me/yopW/install';

}
